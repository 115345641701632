<template>
  <div>
    <v-card v-if="Object.keys(items).length !== 0" outlined>
      <v-card-title class="align-start">
        <span class="font-weight-semibold text-base">{{ title }}</span>
      </v-card-title>
      <!--v-card-text v-if="isLoading"></v-card-text-->
      <v-card-text>
        <v-row>
          <v-col
            v-for="(item, key, index) in fullItems"
            :key="key"
            cols="6"
            lg="2"
            md="4"
          >
            <div class="d-flex">
              <v-avatar size="44" color="primary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ item.icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">CA</p>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="text--primary text-xl font-weight-bold"
                      v-on="on"
                      v-bind="attrs"
                      >{{ item.value }}</span
                    >
                  </template>
                  <span>{{ item.raw }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";

import {
  mdiSpeedometer,
  mdiMonitorCellphone,
  mdiMonitorEye,
  mdiCurrencyEur,
  mdiFormatRotate90,
} from "@mdi/js";
import { formatNumberCompact, formatCurrencyCompact } from "@/utils/formatting";

const mdiAvailable = {
  Speedometer: mdiSpeedometer,
  MonitorCellphone: mdiMonitorCellphone,
  MonitorEye: mdiMonitorEye,
  CurrencyEur: mdiCurrencyEur,
  FormatRotate90: mdiFormatRotate90,
};
const mdiAvailableKeys = Object.keys(mdiAvailable);

// const example = {
//   ca: { label: "CA", type: "CurrencyEur", value: 10123.4567 },
// };

export default {
  name: "KpiDarkBlue",
  props: {
    title: {
      type: String,
      default: "",
    },
    isLoading: false,
    items: {
      type: Object,
      default() {
        return example;
      },
      validator(items) {
        for (const item of Object.values(items)) {
          if (!mdiAvailableKeys.includes(item.type)) {
            console.warn("Component KpiDarkBlue - item type is not available");
            return false;
          }
        }
        return true;
      },
    },
  },
  created() {},
  data() {
    return {
      icons: {
        mdiSpeedometer,
        mdiMonitorCellphone,
        mdiMonitorEye,
        mdiCurrencyEur,
        mdiFormatRotate90,
      },
    };
  },
  computed: {
    fullItems() {
      let fullItems = {};
      Object.entries(this.items).forEach(([key, item], index) => {
        console.log(`Index: ${index}, Key: ${key}, item:`, item);
        item.icon = mdiAvailable[item.type];
        item.raw = item.value;
        item.value =
          item.type == "CurrencyEur"
            ? formatCurrencyCompact(item.value)
            : formatNumberCompact(item.value);
        fullItems[key] = item;
      });
      console.log("fullItems", fullItems);
      return fullItems;
    },
  },
  watch: {},
};
</script>

<style scoped></style>
